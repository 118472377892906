.actions-block {
  padding: 0 15px 5px;
  // margin-bottom: 20px;
  //margin-bottom: 8px;
  background-color: $background-color;
  // border-radius: 2px;
}

.users-table {
  .actions-block {
    display: flex;
    justify-content: end;
  }
}

.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 0;
}

.search-outlined {
  margin-right: 10px;
}

.pointer-events {
  pointer-events: none;
}

.my-entries-upper {
  display: flex;
  margin-bottom: 30px;
}

.no-entries {
  font-size: 32px;
  color: $k100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.filter-my-entries {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px !important;
}

.entries-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.team-btns {
  margin-top: 100px;
}

@media(max-width: 1425px){
  .my-entries-upper {
    flex-direction: column;
    justify-content: center;
  }
  .filter-my-entries{
     margin: 0 auto;
  }
}
@media(max-width: 1000px){

  .filter-my-entries{
     margin: 0 auto;
     margin-bottom: 10px;
     max-width: unset !important;
  }
}

