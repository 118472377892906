.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: all;
  opacity: 0;
  pointer-events: none;
}
.overlay.show {
  pointer-events: all;
  z-index: 50;
  opacity: 1;
}

.time-input-entries {
  //width: 480px;
  max-width: 100vw;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  .time-form {
    background: $wht;
    border-radius: 20px;
  }
}

.modal {
  z-index: 450;
  position: fixed;
  right: -100%;
  top: 0;
  opacity: 1;
  transition: ease all 0.55s;
  .required {
    color: #ca1717;
    margin-right: 3px;
  }
  // pointer-events: none;
}
.modal.show {
  pointer-events: all;
  right: 0;
}

.modal {
  //width: 480px;
  //width: 100%;
  min-width: 400px;
  max-width: 100vw;
  height: 100%;
  box-sizing: border-box;
  //overflow-y: scroll;

  .dashboard {
    .card-wrapper {
      display: block;
    }
  }

  .dashboard .card-wrapper .ant-card .ant-card-body {
    padding: 20px 60px 60px;
    background-color: $wht;
  }

  .ant-card {
    width: 100% !important;
    height: 100%;
    box-sizing: content-box;
    box-shadow: none;
    //padding: 60px;
    //padding-bottom: 80px;
    border-radius: 0;

    .ant-card-head {
      background: transparent;
      border-bottom: 0;
      padding: 60px 60px 0;

      .ant-card-head-title {
        @include heading($heading-font-size, 41px);
        text-transform: none;
        padding: 0;
        margin-bottom: 20px;
      }
    }

    .ant-card-body {
      box-sizing: border-box;
      .ant-form-item:last-child {
        margin-bottom: 0;
      }
      .ant-form-item-control-input-content {
        margin: 0 !important;
        .ant-select-multiple .ant-select-selection-placeholder {
          right: unset;
        }
      }
      .ant-input {
        border-radius: 4px;
      }
      .ant-btn-primary {
        text-transform: none;
      }
      .ant-btn-secondary {
        border: none;
        box-shadow: none;
      }
    }
  }
}
.modal-select {
  width: 100%;
}
.modal::-webkit-scrollbar {
  display: none;
}

.deleteModal {
  width: 80% !important;
  .ant-modal-content{
    .ant-modal-body{
      height: unset;
    }
  }
  max-width: 480px;
  
  .ant-modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    .ant-btn {
      width: 45%;
      border: 1px solid #0a80d8;
      border-radius: 4px;
    }
    .ant-btn-primary {
      border: none;
    }
    .ant-btn:hover {
      color: inherit;
    }
    .ant-btn-primary:hover {
      color: #fff;
    }
  }
}
.delete-user {
  position: absolute;
  right: 5%;
  top: 1%;
  cursor: pointer;
}

.x-btn {
  position: absolute;
  top: 70px;
  right: 60px;
}

.modal-popup {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 10px 0;
  width: auto;
  font-size: 14px;
}

.work-entry-modal {
  max-width: 800px !important;
  .ant-modal-header {
    border-bottom: 0;
    .ant-modal-title {
      h3 {
        font-size: 16px;
        text-align: center;
        font-weight: 700;
      }
    }
  }
  .ant-modal-footer {
    border-top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    margin-top: 20px;
    .ant-btn {
      width: 200px;
      height: 40px;
    }
    .ant-btn-primary {
      margin-right: 10px;
    }
  }
  .ant-modal-content {
    padding: 20px;
  }
  .ant-modal-body {
    // display: flex;
    justify-content: space-between;
    padding: 10px 30px !important;
    .modal {
      width: 50%;
      padding: 0;
    }
  }
  #hourInput,
  #minutInput {
    width: 60px;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    outline: none;
    padding: 5px;
    padding-left: 10px;
  }

  #hourInput {
    margin-right: 10px;
  }
  .work-description {
    width: 100%;
    height: 82px !important;
    border-radius: 4px;
  }

  .date-picker {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    padding: 10px 0;
    h3,
    .ant-picker {
      width: 70%;
    }
  }
  .ant-btn {
    line-height: normal;
  }
}

.stop-scroll-modal {
  overflow-y: hidden !important;
}

.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
[data-theme='dark'] .site-calendar-demo-card {
  border: 1px solid #303030;
}

//Hour Inputs and Calendar styling
.work-entry-modal {
  .ant-modal-body {
    display: flex;
    height: auto;
    overflow-y: hidden;
  }
  h3 {
    font-size: 14px;
  }
  .time-inputs-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .time-input-group {
      .time-inputs {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .input-group,
  .input-group-sm {
    width: 60px;
    height: 30px;
    position: relative;
    margin-right: 10px;
    .form-control {
      width: 60px;
      height: 30px;
      padding-left: 10px;
      border: 0.4px solid #ccc7f5;
      border-radius: 4px;
    }
    .input-group-prepend {
      position: absolute;
      bottom: -1px;
      right: 10px;
      #input-spinner-left-button {
        transform: scale(0.75, 1.5);
      }
    }
    .input-group-append {
      position: absolute;
      top: -1px;
      right: 10px;
      #input-spinner-right-button {
        transform: scale(0.75, 1.5);
      }
    }
    .input-group-prepend,
    .input-group-append {
      transform: rotate(270deg);
    }
  }
  .date-picker {
    .ant-picker-calendar-header {
      justify-content: flex-start;
    }
    .ant-radio-group {
      display: flex;
    }
    .ant-select-arrow {
      top: 13px;
    }
    .ant-picker-date-panel .ant-picker-content th {
      text-align: center;
    }
    td {
      max-width: 25px;
      max-height: 20px;
    }
  }
}

.time-form {
  background: $wht;
  border-radius: 20px;
  padding: 20px;
  margin-right: 20px;
}

.my-entries-work-entry {
  display: flex;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  .modal {
    flex-grow: 1;
    margin-right: 30px;
    .modal-select {
      margin-bottom: 20px;
    }
    .time-inputs {
      .single-time-input {
        .time-input-label {
          display: block;
          text-align: left;
          font-size: 10px;
          padding: 4px 2px;
        }
      }
    }
    .my-entries-work-entry-btns {
      width: 100%;
      margin-top: 20px;
      .ant-btn-primary,
      .ant-btn-secondary {
        height: 38px;
        padding: 4px 15px;
        letter-spacing: 0.05em;
        line-height: 1.5715;
      }
    }
  }
}
@media(max-width: 1425px){
  .my-entries-work-entry{
    width: 100%;
    margin-bottom: 20px;
    max-width: unset !important;
  }
}
@media(max-width: 825px){
  .work-entry-modal{
    .date-picker{
      .ant-picker-calendar-header{
        flex-direction: column;
        .ant-select, .ant-radio-group{
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
        }
        .ant-radio-group label{
          width: 50%;
        }
      }
    }
  }
}
@media(max-width: 800px){
  .modal{
    min-width: unset;
  }
  .my-entries-work-entry{
    .modal{
      margin-right: 0;
      min-width: unset;
    }
  }
  .work-entry-modal{
    flex-direction: column;
    .date-picker{
      order: -1;
      width: 100%;
    }
  }
}
@media(max-width: 480px){
  .modal.show{
    width: 100%;
  }
  .work-entry-modal{
    flex-direction: column;
    .date-picker{
      order: -1;
      width: 100%;
      .ant-picker-calendar-header{
        display: flex;
        flex-direction: column;
        .ant-select, .ant-radio-group{
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          width: 100%;
        }
        .ant-radio-group label{
          width: 50%;
        }
      }
    }
  }
}