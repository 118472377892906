.admin_header {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 40px;

  > .anticon {
    color: #fff;
    font-size: 30px;
    outline: none;
    margin-right: 20px;

    @include media('<=960px') {
      font-size: 23px;
    }
  }

  .ant-dropdown-link {
    font-size: 16px;
    color: #fff;
    margin-left: 2rem;
    cursor: pointer;
    //margin-right: 30px;

    &:hover {
      text-decoration: none;
    }

    @include media('<=960px') {
      font-size: 12px;
    }

    i {
      vertical-align: baseline;
      margin-left: 6px;
    }
  }

  .ant-select-arrow {
	  top: 38%;
  }

  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
      }
    }
  }
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
    font-size: 10px;
    vertical-align: baseline;
    margin-right: 100px;
    margin-left: 5px;
}

.admin_header {
  line-height: 60px;
}

.ant-layout-header span {
  margin-top: 0 !important;
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: #ffffff;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

@media only screen and (max-width: 380px) {
  .admin_header {
    .ant-dropdown-link {
      margin-right: 10px;
    }
  }
}

.logo-desktop{
  @include media("<=lg"){
    display:none;
  }
}
.logo-mobile{
  display:none;

  @include media("<=lg"){
    display: none;
    width: 100px;
    right: -22px;
    position: relative;
  }
}

.btn-collapse{
  display: none;

  @include media("<=lg"){
    display: block;
    z-index: 1000;
    position: fixed;
    width: 100%;
  }
}
@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
}

.nav-menu-collapsed{

  @include media("<=lg"){
    display: block;
    //flex: 0 0 66% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    z-index: 1000;
    margin-top: 38px;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
  }
}

.nav-display{
  display:block;

  @include media("<=lg"){
    display: none;
  }
}
